import * as VideoManager from './VideoManager.js';
import * as HostController from './HostController.js';

export function Initialize() {
	document.addEventListener('video_ended', OnVideoEnd);
	document.addEventListener('video_cuepoint', CuePointHandler);

	setTimeout(() => {
		for (let i = 0; i < 4; i++) {
			if (document.getElementById('ves-option-' + (i + 1)) != null) {
				document
					.getElementById('ves-option-' + (i + 1))
					.addEventListener('click', () => {
						document.getElementById('pc-videoend').style.display = 'none';
						document.getElementById('video').classList.remove('d-none');
						MakeDecision(i + 1);
					});
			}
		}
	}, 1000);
}

//////////////////
// Public functions
////////////////

export function StartScene(tag) {
	document.getElementById('intro-sound').pause();
	document.activeElement.blur();
	HostController.WSCall({ type: 'scenario-start' });
	document.getElementById('video').classList.remove('d-none');

	let newScene = scenarios[tag];
	if (newScene != null) {
		VideoManager.Load(newScene.videoId, newScene.decisionTimestamp);
		currentScene = tag;

		// Setup decisions

		SetupDecisions(newScene.decisions);
	}
}

//////
// Scenario Handling
//////////

let currentScene;
let currentDecision;

let scenarioEndEvent = new Event('scenario_end');

let result = false;

function OnVideoEnd() {
	if (result) {
		document.dispatchEvent(scenarioEndEvent);
		// document.getElementById('ves').classList.add('active');
		document.getElementById('video').classList.add('d-none');
		HostController.WSCall({ type: 'scenario-end' });
	} else {
		//
	}
}

function CuePointHandler(event) {
	switch (event.detail.data.event) {
	case 'end':
		VideoManager.Pause();
		OnVideoEnd();
		break;
	case 'decisions':
		VideoManager.Pause();
		ShowDecisions();
		HostController.StartDecisions(scenarios[currentScene].decisions);
		break;
	}
}

////////////
// Decisions
///////////

export function MakeDecision(id) {
	VideoManager.Load(scenarios[currentScene].decisions[id - 1].videoId),
	scenarios[currentScene].decisionTimestamp;
	result = true;
	//VideoManager.SetPlaybackPosition(scenarios[currentScene].decisions[id - 1].timestamp);
	//VideoManager.Play();
	HideDecisions();
}

function ShowDecisions() {
	document.getElementById('host-decisions').classList.remove('d-none');
	document.getElementById('decisions-sound').play();
	document.getElementById('host-answercounter').classList.remove('d-none');
	StartTimer();
}

function HideDecisions() {
	HostController.WSCall({ type: 'decision-end' });
	document.getElementById('host-decisions').classList.add('d-none');
}

function SetupDecisions(decisions) {
	for (let i = 0; i < decisions.length; i++) {
		document.getElementById('decision-' + (i + 1)).innerHTML =
      decisions[i].text;
		document.getElementById('ves-option-' + (i + 1)).innerHTML =
      decisions[i].text;
	}
}

////////////
// Timer
//////////////

function StartTimer() {
	document.getElementById('timer-bar-fill').classList.remove('fill-idle');
	document.getElementById('timer-bar-fill').classList.add('fill-decreasing');
	document
		.getElementById('timer-bar-fill')
		.addEventListener('animationend', TimerCallback);
}

function TimerCallback() {
	HostController.VotingFinished();
}

export function EndTimer() {
	document.getElementById('timer-bar-fill').classList.remove('fill-decreasing');
	document.getElementById('timer-bar-fill').classList.add('fill-idle');
}

///////
// Data
///////

export let scenarios = {
	// Online

	0: {
		videoId: '488979387',
		decisionTimestamp: 45,
		endthumbnail: '',
		decisions: [
			// Decision 1
			{
				text: 'Delen met de klas',
				videoId: '493728977',
			},
			// Decision 2
			{
				text: 'Melden bij mentor',
				videoId: '493729129',
			},
			// Decision 3
			{
				text: 'Liken en reageren',
				videoId: '493729206',
			},
			// Decision 4
			{
				text: 'Afkeuren in comment',
				videoId: '493729291',
			},
		],
	},
	// Aanmeld formulier

	1: {
		videoId: '505560221',
		decisionTimestamp: 65,
		decisions: [
			// Decision 1
			{
				text: 'Noni gelijk geven',
				videoId: '505560443',
			},
			// Decision 2
			{
				text: 'Fatma aanmoedigen',
				videoId: '505560482',
			},
			// Decision 3
			{
				text: 'Fatma ontmoedigen',
				videoId: '505560519',
			},
			// Decision 4
			{
				text: 'Iedereen uitnodigen',
				videoId: '505560585',
			},
		],
	},

	// Achterin de bus

	2: {
		videoId: '488981091',
		decisionTimestamp: 67,
		decisions: [
			// Decision 1
			{
				text: 'Boos worden',
				videoId: '493729391',
			},
			// Decision 2
			{
				text: 'Jesse steunen',
				videoId: '493729499',
			},
			// Decision 3
			{
				text: 'Niets doen',
				videoId: '498291421',
			},
			// Decision 4
			{
				text: 'Mee lachen',
				videoId: '493729941',
			},
		],
	},

	// Dugout

	3: {
		videoId: '488981435',
		decisionTimestamp: 51,
		decisions: [
			// Decision 1
			{
				text: 'Ook schreeuwen',
				videoId: '493730076',
			},
			// Decision 2
			{
				text: 'Niets doen',
				videoId: '493730220',
			},
			// Decision 3
			{
				text: 'Denise kalmeren',
				videoId: '493730234',
			},
			// Decision 4
			{
				text: 'Er iets van zeggen',
				videoId: '493730323',
			},
		],
	},

	// De cornervlag

	4: {
		videoId: '488979533',
		decisionTimestamp: 62,
		decisions: [
			// Decision 1
			{
				text: 'Doorspelen',
				videoId: '493730727',
			},
			// Decision 2
			{
				text: 'Stoppen met spelen',
				videoId: '493730856',
			},
			// Decision 3
			{
				text: 'Scheids roepen',
				videoId: '493730946',
			},
			// Decision 4
			{
				text: 'Niets doen',
				videoId: '493731062',
			},
		],
	},

	// Penalty

	5: {
		videoId: '488981750',
		decisionTimestamp: 67,
		decisions: [
			// Decision 1
			{
				text: 'Haar de bal geven',
				videoId: '493731134',
			},
			// Decision 2
			{
				text: 'Snel kiezen',
				videoId: '493731440',
			},
			// Decision 3
			{
				text: 'Hem gelijk geven',
				videoId: '493731521',
			},
			// Decision 4
			{
				text: 'Niemand kiezen',
				videoId: '493732560',
			},
		],
	},

	// Kleedkamer

	7: {
		videoId: '505560625',
		decisionTimestamp: 55,
		decisions: [
			// Decision 1
			{
				text: 'Michiel kalmeren',
				videoId: '505560723',
			},
			// Decision 2
			{
				text: 'Melden bij docent',
				videoId: '505560762',
			},
			// Decision 3
			{
				text: 'Lois troosten',
				videoId: '505560814',
			},
			// Decision 4
			{
				text: 'Verder vieren',
				videoId: '505560850',
			},
		],
	},

	// Tafelvoetbal

	8: {
		videoId: '493769018',
		decisionTimestamp: 77,
		decisions: [
			// Decision 1
			{
				text: 'Verder spelen',
				videoId: '493732727',
			},
			// Decision 2
			{
				text: 'Bram troosten',
				videoId: '493732827',
			},
			// Decision 3
			{
				text: 'Er iets van zeggen',
				videoId: '493732922',
			},
			// Decision 4
			{
				text: 'Mee lachen',
				videoId: '493732948',
			},
		],
	},

	// De Parkeerplaats

	9: {
		videoId: '505560882',
		decisionTimestamp: 47,
		decisions: [
			// Decision 1
			{
				text: 'Niets doen',
				videoId: '505560956',
			},
			// Decision 2
			{
				text: 'Bal niet geven',
				videoId: '505560997',
			},
			// Decision 3
			{
				text: 'Bal schieten',
				videoId: '505561032',
			},
			// Decision 4
			{
				text: 'Er iets van zeggen',
				videoId: '505561065',
			},
		],
	},
	// rode kaart
	6: {
		videoId: '1025378521',
		decisionTimestamp: 112,
		decisions: [
			// Decision 1
			{
				text: 'Gedrag goedkeuren',
				videoId: '1029631870',
			},
			// Decision 2
			{
				text: 'Boos worden',
				videoId: '1029631899',
			},
			// Decision 3
			{
				text: 'Streng toespreken',
				videoId: '1029631929',
			},
			// Decision 4
			{
				text: 'Begrip tonen',
				videoId: '1029631956',
			},
		],
	},
};
Initialize();
